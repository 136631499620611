import React, { useState, useEffect } from "react"
import axios from "axios"
import jsonpAdapter from "axios-jsonp"
import * as Scroll from "react-scroll"
import { FaSnowflake, FaSun } from "react-icons/fa"
import { GiThreeLeaves, GiFlowerPot } from "react-icons/gi"
import { Element } from "react-scroll"
import dayjs from "dayjs"

import SeasonalButtons from "../SeasonalButton"
import SeasonGrid from "../SeasonGrid"
import SeasonDescription from "../SeasonDescription"
import SideBar from "../SideBar"
import cloudinaryOptimize from "../../helpers/cloudinaryOptimize"
import useStories from "../../hooks/useStories"

const SeasonalEvents = () => {
  const [isFetching, setIsFetching] = useState(true)
  const [activeToggle, setActiveToggle] = useState("all")
  const [allEvents, setAllEvents] = useState([])
  const [filterText, setFilterText] = useState("")
  const [toggleByDays, setToggleByDays] = useState(false)
  const [date, setDate] = useState(new Date())
  const [regularEvents, setRegularEvents] = useState([])
  const [recurringEvents, setRecurringEvents] = useState([])

  const { getStoryByTag } = useStories()

  const springContent = getStoryByTag("all-year-spring")
  const summerContent = getStoryByTag("all-year-summer")
  const fallContent = getStoryByTag("all-year-fall")
  const winterContent = getStoryByTag("all-year-winter")

  const id = "bzn-yO3xgUsKQCS7GWg0Q2ewbQ"

  const regularEventsURL = `https://data.prod.gonation.com/profile/events?profile_id=${id}`
  const recurringEventsURL = `https://data.prod.gonation.com/profile/recurringevents?profile_id=${id}`

  useEffect(() => {
    const getEvents = async () => {
      setRegularEvents(await (await fetchEvents(regularEventsURL)).data.events)
      setRecurringEvents(
        await (await fetchEvents(recurringEventsURL)).data.events
      )
      setIsFetching(false)
    }
    getEvents()
  }, [])

  const fetchEvents = async type => {
    try {
      return await axios({ url: type, adapter: jsonpAdapter })
    } catch (e) {
      console.error(`Error fetching events @ ${type}`, e)
    }
  }

  useEffect(() => {
    if (!isFetching) {
      const allEvents = recurringEvents.concat(regularEvents).map(data => {
        const { eventDays } = data
        return {
          isRecurring: eventDays !== undefined,
          ...data,
        }
      })

      setAllEvents(allEvents)
    }
  }, [isFetching])

  const textIsInCategory = tags => {
    return tags.filter(tag =>
      tag.toLowerCase().includes(filterText.toLowerCase())
    ).length
  }

  const filterFunction = evt => {
    return (
      evt.name.includes(filterText) ||
      textIsInCategory(evt.eventTags) ||
      evt.business.name.includes(filterText)
    )
  }

  const typeFnc = evt => {
    if (activeToggle === "all") {
      return evt
    }
    if (activeToggle === "recurring") {
      return evt.isRecurring
    }
    if (activeToggle === "single") {
      return !evt.isRecurring
    }
  }

  const dayFilter = evt => {
    if (toggleByDays) {
      if (evt.isRecurring) {
        const days = Object.keys(evt.eventDays)
      }
    }
    return evt
  }

  const filterEvents = season => {
    if (season === "fall") {
      return allEvents.filter(
        evt =>
          dayjs(evt.startAt).format("MM") === "09" ||
          dayjs(evt.startAt).format("MM") === "10" ||
          dayjs(evt.startAt).format("MM") === "11"
      )
    } else if (season === "winter") {
      return allEvents.filter(evt => {
        return (
          dayjs(evt.startAt).format("MM") === "12" ||
          dayjs(evt.startAt).format("MM") === "01" ||
          dayjs(evt.startAt).format("MM") === "02"
        )
      })
    } else if (season === "spring") {
      return allEvents.filter(
        evt =>
          dayjs(evt.startAt).format("MM") === "03" ||
          dayjs(evt.startAt).format("MM") === "04" ||
          dayjs(evt.startAt).format("MM") === "05"
      )
    } else if (season === "summer") {
      return allEvents.filter(
        evt =>
          dayjs(evt.startAt).format("MM") === "06" ||
          dayjs(evt.startAt).format("MM") === "07" ||
          dayjs(evt.startAt).format("MM") === "08"
      )
    }
  }

  // * BEGIN CODE REGARDING SEASONAL LOGIC
  const seasons = [
    {
      name: fallContent?.title,
      img: fallContent?.media?.[0]?.cloudinaryId
        ? cloudinaryOptimize(fallContent?.media?.[0]?.cloudinaryId, 200)
        : "",
      ico: color => <GiThreeLeaves color={color} size={"1.25rem"} />,
      desc: fallContent?.body,
      events: filterEvents("fall"),
    },
    {
      name: winterContent?.title,
      img: winterContent?.media?.[0]?.cloudinaryId
        ? cloudinaryOptimize(winterContent?.media?.[0]?.cloudinaryId, 200)
        : "",
      ico: color => <FaSnowflake color={color} size={"1.25rem"} />,
      desc: winterContent?.body,
      events: filterEvents("winter"),
    },
    {
      name: springContent?.title,
      img: springContent?.media?.[0]?.cloudinaryId
        ? cloudinaryOptimize(springContent?.media?.[0]?.cloudinaryId, 200)
        : "",
      ico: color => <GiFlowerPot color={color} size={"1.25rem"} />,
      desc: springContent?.body,
      events: filterEvents("spring"),
    },
    {
      name: summerContent?.title,
      img: summerContent?.media?.[0]?.cloudinaryId
        ? cloudinaryOptimize(summerContent?.media?.[0]?.cloudinaryId, 200)
        : "",
      ico: color => <FaSun color={color} size={"1.25rem"} />,
      desc: summerContent?.body,
      events: filterEvents("summer"),
    },
  ]
  // * END CODE REGARDING SEASONAL LOGIC

  return (
    <>
      <SeasonalButtons data={seasons} />
      <section className="section all-seasons">
        <SideBar data={seasons} />

        {seasons.map(season => (
          <Element
            name={season.name}
            className={`season-columns ${season.name}`}
          >
            <div className="column__season-description">
              <SeasonDescription data={season} />
            </div>
            <div className="column__season-grid">
              <SeasonGrid season={season} />
            </div>
          </Element>
        ))}
      </section>
    </>
  )
}

export default SeasonalEvents
