import React from "react"
import Fade from "react-reveal/Fade"
import dayjs from "dayjs"
import avatarCoverStringBuilder from "../helpers/avatarCoverStringBuilder"

const SeasonalEvent = ({ data }) => {
  const type = Math.random() <= 0.2 ? "alt" : "normal"

  const getDefaultImg =
    "https://res.cloudinary.com/gonation/c_scale,w_900,c_fit,fl_lossy,f_auto,q_80/gonation.data.prod/default/img-evt-cover-full.png"

  const DESC_LENGTH = 200

  const truncateDesc = description =>
    description.length > DESC_LENGTH
      ? `${description.substring(0, DESC_LENGTH)}...`
      : description

  return (
    <div
      className={`seasonal-event ${type === "alt" ? "alt" : ""}`}
      //   todo conditionally choose background if alt event
      style={{
        backgroundImage: `linear-gradient(rgb(0 0 0 / 0%) 0%, rgb(0 0 0 / 82%) 85%), url(${avatarCoverStringBuilder(
          data.imageBaseUrl,
          data.imagePrefix
        )})`,
      }}
    >
      {type === "alt" ? (
        <>
          {/* ALTERNATE DESIGN (DESKTOP ONLY) */}
          <div className="is-hidden-touch">
            <div className="seasonal-event-cover">
              <div className="left">
                <img
                  src={avatarCoverStringBuilder(
                    data.imageBaseUrl,
                    data.imagePrefix
                  )}
                  alt={data.name}
                />
                <div className="seasonal-event__date">
                  <span>{dayjs(data.starts).format("MMMM")}</span>
                </div>
              </div>
              <div className="right">
                <div className="overlay-content">
                  <div className="content-left">
                    <p>{data.name}</p>
                  </div>
                  <div className="content-right">
                    <p>{truncateDesc(data.description)}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ALTERNATE DESIGN -> MOBILE */}
          <div className="is-hidden-desktop">
            <div className="seasonal-event__date">
              <span>{dayjs(data.starts).format("MMMM")}</span>
            </div>
            <Fade>
              <div className="has-text-centered">
                <p className="seasonal-event-title">{data.name}</p>
              </div>
            </Fade>
          </div>
        </>
      ) : (
        <div className="slide-up-wrapper">
          {/* NORMAL EVENT ACROSS ALL SCREEN WIDTHS */}
          <div className="seasonal-event__date">
            <span>{dayjs(data.starts).format("MMMM")}</span>
          </div>
          <Fade>
            <div className="has-text-centered">
              <p className="seasonal-event-title">{data.name}</p>
              <p className="seasonal-event-desc">
                {truncateDesc(data.description)}
              </p>
            </div>
          </Fade>
        </div>
      )}
    </div>
  )
}

export default SeasonalEvent
