import React, { useState } from "react"

import EventsModalBasic from "./events/EventsModalBasic"
import SeasonalEvent from "../components/SeasonEvent"

const SeasonGrid = ({ season }) => {
  const [openEvent, setOpenEvent] = useState(null)

  const closeModal = () => setOpenEvent(null)

  function run() {
    season.events.map(evt => {})
  }
  run()
  return (
    <div className="season-event-grid">
      {season.events.map(evt => {
        return (
          <div key={evt.name} onClick={() => setOpenEvent(evt)}>
            <SeasonalEvent data={evt} />
          </div>
        )
      })}
      {openEvent ? (
        <EventsModalBasic
          event={openEvent}
          modalShowing={openEvent}
          closeModal={closeModal}
        />
      ) : (
        ""
      )}
    </div>
  )
}

export default SeasonGrid
