import React, { useState } from "react"
import { Link } from "react-scroll"
import Sticky from "react-sticky-el"

const seasonColors = {
  FALL: "#fa8f04",
  WINTER: "#bfefff",
  SUMMER: "#ffcc33",
  SPRING: "#f5ad94",
}

const SideBar = ({ data }) => {
  const [isFixedToggled, setIsFixedToggle] = useState(false)
  const [activeSeason, setActiveSeason] = useState("FALL")

  const handleFixedChange = () => setIsFixedToggle(!isFixedToggled)

  const handleSetActive = season => setActiveSeason(season)

  return (
    <div className={`scroll-links ${isFixedToggled && "is-scrolled"} `}>
      <Sticky onFixedToggle={handleFixedChange}>
        <ul
          className={`active-season__${activeSeason}`}
          style={{ backgroundColor: seasonColors?.[activeSeason ?? "FALL"] }}
        >
          {data.map(season => {
            return (
              <Link
                to={season.name}
                activeClass="active"
                offset={-100}
                duration={750}
                smooth
                spy={true}
                hashSpy
                // onSetActive={to => to === season.name}
                onSetActive={handleSetActive}
              >
                <li
                  className={
                    season.name === activeSeason ? "active-season" : ""
                  }
                >
                  {season.ico()}
                  <span>{season.name}</span>
                </li>
              </Link>
            )
          })}
        </ul>
      </Sticky>
    </div>
  )
}

export default SideBar
