import React from "react"

const SeasonDescription = ({ data }) => {
  const { name, desc } = data
  return (
    <div className="season-description">
      <h3 className="season-title">{name}</h3>
      <div
        className="season-description__paragraph"
        dangerouslySetInnerHTML={{ __html: desc }}
      ></div>
    </div>
  )
}

export default SeasonDescription
