import React from "react"
import SeasonalEvents from "../components/DiscoverEvents/SeasonalEvents"
import Layout from "../components/Layout"
import "../styles/seasons.scss"

const DocksideAllYear = () => {
  return (
    <>
      <Layout pageTitle="Dockside All Year">
        <SeasonalEvents />
      </Layout>
    </>
  )
}

export default DocksideAllYear
